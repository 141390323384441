import { axiosNoAuth } from "../axios";

export const resetPasswordRequest = async (data: any) => {
  try {
    let response = await axiosNoAuth.post(
      `/api/shippers/forgot-password`,
      data
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const submitNewPass = async (data: any, token: string) => {
  try {
    let response = await axiosNoAuth.post(
      `/api/shippers/reset-password?key=${token}`,
      {
        password: data.password,
        key: token,
      }
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
