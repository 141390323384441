import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Loader from "../loader/loader";
import axios from "../axios";
import { AuthContext } from "../context/auth";

function VerifyRoute({ children, ...rest }) {
  const [accountLoading, setAccountLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  let location = useLocation();
  const { isAuthenticated, isVerified, verify, account, profile } = useContext(
    AuthContext
  );

  useEffect(() => {
    let mounted = true;

    if (account) {
      setAccountLoading(false);
      return;
    }

    const token = localStorage.getItem("jwtToken");
    if (!token) {
      setAccountLoading(false);
      return;
    }

    axios
      .get("/api/shippers/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (mounted) {
          setAccountLoading(false);
        }
        profile(response.data);
      })
      .catch((error) => {
        setAccountLoading(false);
        console.error(error);
      });

    return function cleanup() {
      mounted = false;
    };
  }, [profile, account]);

  useEffect(() => {
    let mounted = true;

    if (isVerified) {
      setAuthLoading(false);
      return;
    }

    if (!account) {
      setAuthLoading(false);
      return;
    }

    if (mounted) {
      setAuthLoading(false);
    }
    verify(account.user.phone_verified);

    return function cleanup() {
      mounted = false;
    };
  }, [account, verify, isVerified]);

  if (accountLoading || authLoading) {
    return <Loader />;
  }

  const { from } = location.state || { from: { pathname: "/create" } };
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          isVerified ? (
            <Redirect
              to={{
                pathname: from.pathname,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default VerifyRoute;
