/* eslint-disable */
// @flow

import React from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { ClearCacheProvider } from "react-clear-cache";
import { BrowserRouter as Router } from "react-router-dom";
import { QuatrixTheme } from "./qui/theme";
import AuthProvider from "./context/auth";
import { Toaster } from "react-hot-toast";
import Routes from "./routes";
import "./map.css";

const engine = new Styletron();

function App() {
  return (
    <ClearCacheProvider duration={5000} auto={true}>
      <StyletronProvider value={engine}>
        <Toaster position="top-right" reverseOrder={false} />
        <BaseProvider theme={QuatrixTheme}>
          <AuthProvider>
            <Router>
              <Routes />
            </Router>
          </AuthProvider>
        </BaseProvider>
      </StyletronProvider>
    </ClearCacheProvider>
  );
}

export default App;
