// @flow

import { createTheme } from "baseui";

const primitives = {
  primaryFontFamily:
    'Regular, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
  primary: "#f5a623",
};

const secondaryFontFamily =
  'Medium, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif';

const tertiaryFontFamily =
  'MoveMedium, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif';

const breakpoints = {
  small: 769,
  medium: 800,
  large: 1216,
  extra: 1440,
};

const ResponsiveTheme = Object.keys(breakpoints).reduce(
  (acc, key) => {
    acc.mediaQuery[key] = `@media (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    breakpoints,
    mediaQuery: {},
  }
);

const overrides = {
  ...ResponsiveTheme,
  colors: {
    primary: "#f6861f",
    // primary50: "#fef6e9",
    // primary100: "#fdedd3",
    // primary200: "#fce4bd",
    // primary300: "#fbdba7",
    // primary400: "#4327F1",
    // primary500: "#331EB7",
    // primary600: "#281791",
    // primary700: "#1B1060",
    primary50: "#fef6e9",
    primary100: "#fce4bd",
    primary200: "#fad391",
    primary300: "#f8c165",
    primary400: "#ac7419",
    primary500: "#7b5312",
    primary600: "#4a320b",
    primary700: "#181103",
    // gray50: '#F6F6F6',
    // gray100: '#EEEEEE',
    // gray200: '#E2E2E2',
    // gray300: '#CBCBCB',
    // gray400: '#AFAFAF',
    // gray500: '#757575',
    // gray600: '#545454',
    // gray700: '#333333',
    // gray800: '#1F1F1F',
    // gray900: '#141414',
    buttonPrimaryFill: "#f5a623",
    // buttonPrimaryText: themePrimitives.white,
    buttonPrimaryHover: "#db8c0a",
    buttonPrimaryActive: "#db8c0a",
    // buttonPrimarySelectedFill: themePrimitives.primary600,
    // buttonPrimarySelectedText: themePrimitives.white,
    // buttonPrimarySpinnerForeground: themePrimitives.primary50,
    // buttonPrimarySpinnerBackground: themePrimitives.primary500,
    pageGray: "#fafafa",
  },
  typography: {
    font1450: { fontFamily: tertiaryFontFamily },
    font1350: { fontFamily: tertiaryFontFamily },
    font1250: { fontFamily: tertiaryFontFamily },
    font1150: { fontFamily: tertiaryFontFamily },
    font1050: { fontFamily: tertiaryFontFamily },
    font950: { fontFamily: tertiaryFontFamily },
    font850: { fontFamily: secondaryFontFamily },
    font750: { fontFamily: secondaryFontFamily },
    font650: { fontFamily: secondaryFontFamily },
    font550: { fontFamily: secondaryFontFamily },
    font450: { fontFamily: secondaryFontFamily },
    font250: { fontFamily: secondaryFontFamily },
    DisplayLarge: { fontFamily: secondaryFontFamily },
    DisplayMedium: { fontFamily: secondaryFontFamily },
    DisplaySmall: { fontFamily: secondaryFontFamily },
    DisplayXSmall: { fontFamily: secondaryFontFamily },
  },
  borders: {},
  sizing: {
    maxPageWidth: "1280px",
    minPageGutter: "48px",
    navBarMinHeight: "48px",
    navBarMaxHeight: "60px",
    navBarMargin: "10px",
    tabBarHeight: "48px",
    createFooterHeight: "88px",
    ContentPaddingTop: "64px",
    createPrimaryButtonMinWidth: "200px",
    insightsDashTableHeight: "calc(100vh - 222px)",
    toastWidth: "348px",
    maxDropdownHeight: "310px",
    scale850: "28px",
    scale950: "36px",
    scale1100: "44px",
    scale1300: "52px",
    scale1500: "60px",
    scale1800: "72px",
    scale2000: "80px",
    scale2500: "100px",
    scale2800: "112px",
    scale5500: "220px",
    gridGap: "24px",
    gridWidth1: "90px",
    gridWidth2: "204px",
    gridWidth3: "318px",
    gridWidth4: "432px",
    gridWidth5: "546px",
    gridWidth6: "660px",
    gridWidth7: "774px",
    gridWidth8: "888px",
    gridWidth9: "1002px",
    gridWidth10: "1116px",
    gridWidth11: "1230px",
    gridWidth12: "1344px",
  },
  lighting: {
    shadowL1: "0 1px 4px hsla(0, 0%, 0%, 0.12)",
    shadowL1Above: "0 -1px 4px hsla(0, 0%, 0%, 0.12)",
    shadowL2: "0 8px 16px hsla(0, 0%, 0%, 0.08)",
    shadowL2Above: "0 -8px 16px hsla(0, 0%, 0%, 0.08)",
    shadowL3: "0 16px 24px hsla(0, 0%, 0%, 0.12)",
    shadowL3Above: "0 -16px 24px hsla(0, 0%, 0%, 0.12)",
  },
};

const QuatrixTheme = createTheme(primitives, overrides);

export default QuatrixTheme;
