/* eslint-disable */
// @flow
import * as React from "react";
import { styled, withStyle } from "baseui";
import { Spinner } from "baseui/spinner";

const Container = styled("div", () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
}));

// const Spinner = withStyle(Spinner, {
//   borderTopColor: "#f5a623",
//   borderRightColor: "#fdedd3",
//   borderBottomColor: "#fdedd3",
//   borderLeftColor: "#fdedd3",
// });

export default () => (
  <Container>
    <Spinner $color="#FFA020"/>
  </Container>
);
