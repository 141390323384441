import * as TYPES from "./constant";
import { resetPasswordRequest, submitNewPass } from "./service";

export function resetPasswordRequestAction(data: any) {
  return (dispatch) => {
    dispatch({ type: TYPES.LOADING });

    resetPasswordRequest(data)
      .then((res) => {
        dispatch({ type: TYPES.SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: TYPES.ERROR, payload: err });
      });
  };
}

export function submitNewPassAction(data: any, token: string) {
  return (dispatch) => {
    dispatch({ type: TYPES.LOADING });

    submitNewPass(data, token)
      .then((res) => {
        dispatch({ type: TYPES.SUCCESS, payload: res });
        setTimeout(() => {
          window.location.href = "/sign-in"
        }, 1000)
      })
      .catch((err) => {
        dispatch({ type: TYPES.ERROR, payload: err });
      });
  };
}
