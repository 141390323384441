import toast from "react-hot-toast";
import { showErrors } from "../utils/errors";
import * as TYPES from "./constant";

export default function ForgotPassReducer(
  state = { isLoading: false, detail: "", close: false },
  action
) {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
        close: false,
      };
    case TYPES.SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        close: true,
      };
    case TYPES.ERROR:
      showErrors(action.payload.errors, action.payload.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload.detail,
        close: false,
      };
    default:
      return state;
  }
}
