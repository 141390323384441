import axios from "axios";

import { baseURL } from "../config";
import { fetchToken } from "../utils/localstorage";

export const axiosWithAuth = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${fetchToken()}`,
    "Content-Type": "application/json",
  },
});

export const axiosNoAuth = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use((response) => {
  return response;
});

export default axios;
