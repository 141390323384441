import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Input } from "../qui/input";
import { Link, useLocation } from "react-router-dom";
import { FormControl } from "baseui/form-control";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledActionBlock,
  StyledContent,
  StyledContentBlock,
  StyledHeading,
  StyledLeftBlock,
  StyledRightBlock,
} from "../sign-in/styled-components";
import { submitNewPassAction } from "./actions";

function ResetPassword() {
  const { register, handleSubmit, watch, errors } = useForm();

  const dispatch = useDispatch();

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const onSubmit = (data) => {
    dispatch(submitNewPassAction(data, query.get("key")));
  };

  const [css] = useStyletron();

  const { isLoading } = useSelector((state) => state.forgot_password);

  return (
    <div
      className={css({
        display: "flex",
        width: "100%",
        justifyContent: "center",
        placeItems: "center",
        height: "100%",
      })}
    >
      <StyledContent>
        <StyledContentBlock>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledHeading>Reset Password</StyledHeading>
            <FormControl
              label="Password"
              error={errors.password && "Provide your password"}
            >
              <Input
                type="password"
                name="password"
                inputRef={register({
                  required: true,
                })}
                placeholder="Enter your password"
              />
            </FormControl>
            <FormControl
              label="Confirm Password"
              error={errors.confirm && errors.confirm.message}
            >
              <Input
                type="password"
                name="confirm"
                inputRef={register({
                  required: true,
                  validate: (value) =>
                    value !== watch("password")
                      ? "Passwords to do not match"
                      : null,
                })}
                placeholder="Confirm your password"
              />
            </FormControl>
            <StyledActionBlock>
              <Button
                type="submit"
                kind="primary"
                isLoading={isLoading}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        flex: "1",
                        paddingTop: $theme.sizing.scale500,
                        paddingBottom: $theme.sizing.scale500,
                        borderTopRightRadius: $theme.sizing.scale100,
                        borderBottomRightRadius: $theme.sizing.scale100,
                        borderTopLeftRadius: $theme.sizing.scale100,
                        borderBottomLeftRadius: $theme.sizing.scale100,
                      };
                    },
                  },
                }}
              >
                Submit
              </Button>
            </StyledActionBlock>
            <StyledActionBlock>
              <StyledLeftBlock>
                <Link to="/sign-in">Sign in to Quatrix</Link>
              </StyledLeftBlock>
              <StyledRightBlock>
                <Link to="/sign-in">Sign up for Quatrix</Link>
              </StyledRightBlock>
            </StyledActionBlock>
          </form>
        </StyledContentBlock>
      </StyledContent>
    </div>
  );
}

export const ResetPasswordPage = ResetPassword;
