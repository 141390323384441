import toast from "react-hot-toast";

export const showErrors = (
  errors: {
    message: string,
    field: string,
  }[],
  detail?: string
) => {
  if (errors && errors.length > 0) {
    for (let i = 0; i < errors.length; i++) {
      toast.error(errors[i].message);
    }
  } else {
    toast.error(detail);
  }
};
