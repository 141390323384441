// @flow

import * as React from "react";
import { Input as BaseInput } from "baseui/input";

const Input = ({ ...props }) => {
  return (
    <BaseInput
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.ParagraphSmall,
            };
          },
        },
        Input: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.ParagraphSmall,
              paddingLeft: $theme.sizing.scale400,
              paddingRight: $theme.sizing.scale400,
            };
          },
        },
        InputContainer: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.ParagraphSmall,
            };
          },
        },
      }}
      {...props}
    />
  );
};

export default Input;
