export function setToken(token) {
  if (token) {
    localStorage.setItem("jwtToken", token);
  } else {
    localStorage.setItem("jwtToken", "");
  }
}

export function fetchToken() {
  return localStorage.getItem("jwtToken");
}

export function removeToken() {
  return localStorage.removeItem("jwtToken");
}

// export const setAxiosGlobal = () => {
//   if (fetchToken()) {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${fetchToken()}`;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
//   return;
// };

export const setHistoryRoute = () => {
  localStorage.setItem("history", window.location.pathname);
};

export const getHistory = () => {
  const event = localStorage.getItem("history");

  if (event) {
    return {
      is_present: true,
      link: event,
    };
  } else {
    return {
      is_present: false,
      link: null,
    };
  }
};

export const removeHistory = () => {
  return localStorage.removeItem("history");
};
