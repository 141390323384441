/* eslint-disable */
import { NotionRenderer } from "react-notion";
import { useStyletron } from "baseui";
import CancelPolicyObject from "./cancel-policy.json";

function CancelPolicy() {
  const [css] = useStyletron();

  return (
    <section
      className={css({
        maxWidth: "1256px",
        margin: "0px auto",
      })}
    >
      <NotionRenderer blockMap={CancelPolicyObject} />
    </section>
  );
}

export default CancelPolicy;
