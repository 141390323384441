import * as TYPES from "./constants";
import { showErrors } from "../utils/errors";

export default function ProfileReducer(
  state = {
    account: {},
    isLoading: false,
    update: false,
  },
  action
) {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
        update: false,
      };
    case TYPES.ERROR:
      showErrors(action.payload.errors, action.payload.detail);
      return {
        ...state,
        detail: action.payload.detail,
        isLoading: false,
        update: false,
      };
    case TYPES.UPDATE_PROFILE:
      return {
        account: action.payload,
        isLoading: false,
        update: true,
      };
    default:
      return state;
  }
}
