import * as TYPES from "./constants";
import { showErrors } from "../utils/errors";

export default function TrackingReducer(
  state = {
    isLoading: false,
    devices: [],
    groups: [],
    detail: "",
    groupsLoading: false,
    positions: [],
    positionsLoading: false,
    single: true,
    recall: true,
    deviceId: null,
    reportsLoading: false,
    report: [],
  },
  action
) {
  switch (action.type) {
    case TYPES.GETTING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.LIST_DEVICES:
      return {
        ...state,
        devices: action.payload,
        isLoading: false,
      };
    case TYPES.ERROR:
      showErrors([], action.payload.detail);
      return {
        ...state,
        detail: action.payload.detail,
        isLoading: false,
      };
    case TYPES.GETTING_GROUPS:
      return {
        ...state,
        groupsLoading: true,
      };
    case TYPES.LIST_GROUPS:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
      };
    case TYPES.GET_POSITIONS:
      return {
        ...state,
        positionsLoading: true,
      };
    case TYPES.LIST_POSITION:
      return {
        ...state,
        positions: action.payload,
        positionsLoading: false,
        single: action.single,
        recall: action.recall,
        deviceId: action.deviceId || null,
      };
    case TYPES.GET_REPORTS:
      return {
        ...state,
        reportsLoading: true,
      };
    case TYPES.REPORT_SUMMARY:
      return {
        ...state,
        reportsLoading: false,
        report: action.payload,
      };
    default:
      return state;
  }
}
